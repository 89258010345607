export default {
  data: function() {
    return {
      pageNavs: [
        {
          id: '01',
          name: '参加店舗リスト',
          path: '/event/:subdomain/:directory/shop',
          routeName: 'EventStoreAttendList',
          includes: false,
        },
        {
          id: '02',
          name: '参加店舗アカウント登録',
          path: '/event/:subdomain/:directory/shop/register',
          routeName: 'EventStoreAttendRegister',
          includes: false,
        },
      ],
    };
  }
};
