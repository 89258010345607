<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="pageNavs" />
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="sheet">
              <template v-slot:headline>店舗アカウントの登録</template>
              <template v-slot:body>
                <ShopForm ref="registerForm" :allowChildUser="false"/>
              </template>
            </Panel>
          </div>
        </div>
      </section>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter>
      <template v-slot:footer>
        <ul class="listGrid">
          <li class="listGrid-item pos-end">
            <ActionButton className="btn btn-main" buttonText="登録する" :handleSubmit="register" />
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
  </div>
</template>

<script>
//mixin
import nav from '@/mixins/nav/listStoreAttend';
//component
import ShopForm from '../Shop/Account/Form.vue'

export default {
  name: 'EventStoreAttendRegister',
  data: function() {
    return {
      pageName: '参加店舗リスト',
    };
  },
  components: {
    ShopForm,
  },
  mixins: [nav],
  methods: {
    async register() {
      const formData = this.$refs.registerForm.validate();
      if (formData) {
        const result = await this.$store.dispatch('attend/registerShop', formData);
        if (result) {
          await this.$router.push({ name: 'EventStoreAttendList' });
          this.$message.created('shop');
        }
      }
    },
  },
};
</script>
